export class LogService {
  static showMessage;
  static async logError(description, entityOrError) {
    try {
      console.error(description, entityOrError);

      entityOrError.metadata = `URL: ${window.location.href}; error:  ${description}`;
      entityOrError.createdAt = Date.now();
      // todo endpoint for errors
    } catch (e) {
      console.error("save error error", e);
    }
  }

  static showAndLogError(description, error) {
    LogService.logError(...arguments);
    const message = `${description}${
      error.message ? ": " + error.message : ""
    }`;
    LogService.showMessage(message, true);
  }
}
