import firebase from './firebase';

export class DataBaseService {

  static async getAllDocuments(collection) {
    const snapshot = await firebase.firestore().collection(collection).get();
    return snapshot.docs.map(doc => doc.data());
  }

  static async getDocumentById(collection, id) {
    const docRef = firebase.firestore().collection(collection).doc(id);
    const doc = await docRef.get();
    if (doc.exists){
      return doc.data();
    } else {
      throw new Error('No such document!');
    }
  }

  static async getDocumentsWhere(collection, property, value) {
    const res = await firebase.firestore().collection(collection).where(property, "==", value).get();
    const docs = [];
    res.forEach(doc => {
      docs.push(doc.data());
    });
    return docs;
  }

  static updateDocument(document, collection) {
    return firebase.firestore().collection(collection).doc(document.id).set(Object.assign({}, document));
  }
}
