import React from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import './style.scss'

const TLink = ({to, children, className}) => {
	return <Link className={`VLink ${className}`} to={to} children={children}/>
};

TLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default TLink;

