import { Route, Switch } from "react-router-dom";
import LoginPage from "../pages/public/pages/LoginPage/LoginPage";
import React from "react";
import ErrorPage from "../pages/public/pages/ErrorPage";
import CreateAccountPage from "../pages/admin/CreateAccountPage/CreateAccountPage";
import { urlsConst } from "../constants/urlsConst";
import SettingsPage from "../pages/user/SettingsPage/SettingsPage";
import ResetPassPage from "../pages/public/pages/ResetPassPage/ResetPassPage";
import {userRolesConst} from "../constants/userRolesConst";
import UsersList from "../pages/admin/UsersList/UsersList";
import ProjectPage from "../pages/user/ProjectPage/ProjectPage";
import DocsPage from "../pages/user/ProjectsPage/ProjectsPage";
import Main from "../pages/public/pages/Main/Main";
import About from "../pages/public/pages/About/About";
import Contacts from "../pages/public/pages/Contacts/Contacts";

export const getUserRoutes = (user) => {
  if (!user) {
    return (
      <Switch>
        <Route exact path={urlsConst.resetPass}>
          <ResetPassPage />
        </Route>
        <Route path={urlsConst.login}>
          <LoginPage />
        </Route>
        <Route path={urlsConst.about}>
          <About />
        </Route>
        <Route path={urlsConst.contacts}>
          <Contacts />
        </Route>
        <Route path={urlsConst.home}>
          <Main />
        </Route>
      </Switch>
    );
  }

  if (user.role === userRolesConst.admin) {
    return (
      <Switch>
        <Route exact path="/">
          <UsersList />
        </Route>
        <Route exact path={urlsConst.userCreation}>
          <CreateAccountPage />
        </Route>
        <Route path="*">
          <ErrorPage />
        </Route>
      </Switch>
    );
  }

  return (
    <Switch>
      <Route exact path="/">
        <DocsPage />
      </Route>
      <Route exact path={urlsConst.settings}>
        <SettingsPage />
      </Route>
      <Route exact path={`${urlsConst.project}:id`}>
        <ProjectPage />
      </Route>
      <Route path="*">
        <ErrorPage />
      </Route>
    </Switch>
  );
};
