import {collections} from "../../constants/collections";
import {DataBaseService} from "./DataBaseService";

export class UserDBService {
  static async getUserByFirebaseId(id) {
    const res = await DataBaseService.getDocumentsWhere(
      collections.users,
      "firebaseId",
      id
    );
    return res[0];
  }

  static updateUser(user) {
    return DataBaseService.updateDocument(
      user,
      collections.users,
    );
  }

  static getAllUsers() {
    return DataBaseService.getAllDocuments(collections.users);
  }
}
