import React, { useState } from "react";
import { AuthService } from "../../../services/AuthService";
import "./style.scss";
import TButton from "../../../material/TButton/TButton";
import MainContext from "../../../contexts/main.context";
import { commonConst } from "../../../constants/commonConst";
import TTextField from "../../../material/TTextField";
import { setFormState } from "../../../helpers/form.helper";
import { LogService } from "../../../services/LogService";
import { useHistory } from "react-router-dom";
import { validEmail, validPassword } from "../../../helpers/validator.helper";
import GlobalLoader from "../../../material/GloabalLoader";

const CreateAccountPage = () => {
  const history = useHistory();
  const { user } = React.useContext(MainContext);
  const [loader, setLoader] = React.useState(false);
  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const createAccount = async () => {
    try {
      setLoader(true);
      await AuthService.createAccount(state.email, state.password);
      history.push("/");
    } catch (e) {
      LogService.showAndLogError("create account error", e);
    } finally {
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  };

  const isDisabled = (state) => {
    try {
      if (!validEmail(state.email)) return true;
      if (!validPassword(state.password)) return true;
      return false;
    } catch (e) {
      LogService.logError("isDisabled error", e);
      return true;
    }
  };

  if (!user) {
    return <span>{commonConst.error}</span>;
  }

  return (
    <div className="CreateAccountPage">
      <GlobalLoader visible={loader} />
      <div className="CreateAccountPage__title">Create account</div>
      <div className="CreateAccountPage__wrapper">
        <TTextField
          onChange={(value) => setFormState("email", value, setState)}
          value={state.email}
          type="email"
          label="email"
          error={!validEmail(state.email)}
        />
        <TTextField
          onChange={(value) => setFormState("password", value, setState)}
          value={state.password}
          type="password"
          label="password"
          error={!validPassword(state.password)}
        />
        <TButton disabled={isDisabled(state)} onClick={createAccount}>
          Create Account
        </TButton>
      </div>
    </div>
  );
};

export default CreateAccountPage;
