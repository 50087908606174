import { collections } from "../../constants/collections";
import {DataBaseService} from "./DataBaseService";
import {ProjectModel} from "../../models/ProjectModel";

export class ProjectDBService {
  static getProjectById(projectId) {
    return DataBaseService.getDocumentById(
      collections.projects,
      projectId
    );
  }

  static async getUserProjects(user) {
    const projects = await DataBaseService.getDocumentsWhere(
      collections.projects,
      "ownerId",
      user.firebaseId
    );
    return projects
      .map(project => new ProjectModel(project))
      .filter(({deleted}) => !deleted);
  }

  static editProject(project) {
    return DataBaseService.updateDocument(
      project,
      collections.projects,
    );
  }

  static removeProject(project) {
    const deletedProject = project.setDeleted();
    return DataBaseService.updateDocument(
      deletedProject,
      collections.projects,
    );
  }
}
