import React from 'react';
import './style.scss'
import background from "../../../../assets/background.jpg";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const MainWrapper = ({children}) => {
	return (
    <div className='MainWrapper' style={{ backgroundImage: `url(${background})` }}>
      <Header/>
      {children}
      <div className='footer'>
        <Footer/>
      </div>
    </div>
  )
};

export default MainWrapper;

