import React, { useState } from "react";
import { AuthService } from "../../../../services/AuthService";
import "./style.scss";
import TButton from "../../../../material/TButton/TButton";
import MainContext from "../../../../contexts/main.context";
import { commonConst } from "../../../../constants/commonConst";
import TTextField from "../../../../material/TTextField";
import { setFormState } from "../../../../helpers/form.helper";
import { LogService } from "../../../../services/LogService";
import { urlsConst } from "../../../../constants/urlsConst";
import TLink from "../../../../material/TLink/TLink";
import { validEmail, validPassword } from "../../../../helpers/validator.helper";
import { useHistory } from "react-router";
// import { UserDBService } from "../../../services/db/UserDBService";

const LoginPage = () => {
  const history = useHistory();
  const { user } = React.useContext(MainContext);
  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const login = async () => {
    try {
      await AuthService.logIn(state.email, state.password);
      history.push("/");
    } catch (e) {
      LogService.showAndLogError("log in error", e);
    }
  };

  const isDisabled = (state) => {
    try {
      if (!validEmail(state.email)) return true;
      if (!validPassword(state.password)) return true;
      return false;
    } catch (e) {
      LogService.logError("isDisabled error", e);
      return true;
    }
  };

  if (user) {
    return <span>{commonConst.error}</span>;
  }

  return (
    <div className="LoginPage">
      <div className="LoginPage__title">Sign in</div>
      <div className="LoginPage__wrapper">
        <TTextField
          onChange={(value) => setFormState("email", value, setState)}
          value={state.email}
          type="email"
          label="email"
          error={!validEmail(state.email)}
        />
        <TTextField
          onChange={(value) => setFormState("password", value, setState)}
          value={state.password}
          type="password"
          label="password"
          error={!validPassword(state.password)}
        />
        <TButton disabled={isDisabled(state)} onClick={login}>
          Log in
        </TButton>
        <div className="LoginPage__links">
          <TLink to={urlsConst.resetPass} children="Forgot password?" />
          <TLink to='/' children="To Web Site" />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
