import {makeId} from "../helpers/util.helper";
import {TDocument} from "./TDocument";

export class ProjectModel {
  constructor(project) {
    if (!project.ownerId) throw new Error("no project.ownerId");
    this.ownerId = project.ownerId;

    if (project.id) {
      this.id = project.id;
    } else {
      this.id = makeId();
    }

    this.name = project.name || "";
    this.description = project.description || "";
    this.createdAt = project.createdAt || Date.now();
    this.custom = project.custom || "";
    this.type = project.type || "";

    if (project.docs && project.docs.length) {
      this.docs = project.docs
        .filter(({deleted}) => !deleted)
        .map(doc => new TDocument(doc));
    } else {
      this.docs = [];
    }

    this.deletedAt = project.deletedAt || null;
    this.deleted = project.deleted || false;
  }

  setDeleted(){
    this.deletedAt = Date.now();
    this.deleted = true;
    return this;
  }
}
