import React from 'react';
import MainWrapper from "../../components/MainWrapper/MainWrapper";
import banner from "../../../../assets/banner-about.jpg";
import Content from "../../components/Content/Content";

const About = () => {
  return (
    <MainWrapper>
      <img className='banner-img' alt='about us'  src={banner} />
      <Content>
        «Проекттехнология» это современное предприятие, деятельность которого сконцентрирована на осуществлении функции
        генерального проектировщика - главной проектной организации, ответственной за реализацию всего комплекса
        проектных и изыскательных работ по объекту проектирования.
        Компания ООО «Полимерстальконструкция» осуществляет полный комплекс услуг в области проектирования промышленных
        объектов любой степени сложности.
        <p></p>
        За 8 лет работы мы смогли собрать коллектив высококвалифицированных сертифицированных инженеров, которые
        способны реализовать любой проект, найти для него оптимальные решения и учесть при этом все требования
        заказчика.
        <p></p>
        За это время было реализовано более 100 объектов промышленного и гражданского назначения, некоторые из
        выполненных нами проектов представлены ниже:
        <ul>

          <li> ОГОК «Строительство цеха переработки шламов марганцевой руды», S≈3200м2, АБК на 60чел.;</li>
          <li> Автосалон Wolksvagen в г. Кривой Рог, S≈2400м2;</li>
          <li> УкрПакЛайн «Производство пластиковой упаковки» г. Днепропетровск, S≈7000м2, АБК на 300 чел;</li>
          <li> ДЗЄО «Реконструкция АБК» г. Днепропетровск, S≈3200м2;</li>
          <li> ВЕСТА «Реконструкция производства аккумуляторов. Участок ТПА» S≈3000м</li>
        </ul>

        Нашими специалистами были выполнены проекты по заданиям таких компаний как Mitsubishi Heavy Industries, Tebodin,
        Sinopec engineering, Schirm, Linde, Commonwealth Dynamics, WhiteWater и т.д. Мы реализуем объекты по всей
        Украине и берем участие в реализации международных проектов в Российской Федерации, Республике Беларусь,
        Казахстане, Израиле, Мьянме (Бирме) и т.д.
      </Content>

    </MainWrapper>
  )
};

export default About;

