import firebase from "./db/firebase";
import { LogService } from "./LogService";

export class AuthService {
  static async signOut(history) {
    try {
      await firebase.auth().signOut();
      history.push("/");
    } catch (e) {
      LogService.showAndLogError("sign out error", e);
    }
  }

  static async logIn(email, password) {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }

  static async resetPass(email) {
    return firebase.auth().sendPasswordResetEmail(email);
  }

  // todo create cloud function
  // https://stackoverflow.com/questions/37517208/firebase-kicks-out-current-user
  static async createAccount(email, password, ownerId) {
    // const user = await firebase.auth().createUserWithEmailAndPassword(email, password);
    // await firebase.auth().signOut();
    // console.log (user.user.uid);
  }

  static async createAccountInDB() {
    // const firebaseId = 'COfmve7TeeRlVlfpMuDHDzOKtks1';
    // const ownerId = '5OwfWMaO8RfNdZkuDmROsLFOmV7';
    // const id = makeId();
    // const newUser = new UserModel({firebaseId, ownerId, id});
    // console.log (newUser);
    // debugger;
    // return DataBaseService.addDocument(
    //   classToObject(newUser),
    //   collections.users,
    //   id
    // );
  }
}
