import React, {useState} from 'react';
import './style.scss'
import {LogService} from "../../../services/LogService";
import Loader from "../../../components/utils/Loader";
import {commonConst} from "../../../constants/commonConst";
import {userRolesConst} from "../../../constants/userRolesConst";
import {UserDBService} from "../../../services/db/UserDBService";
import MainContext from "../../../contexts/main.context";

const UsersList = () => {
  const { user } = React.useContext(MainContext);
  const [users, setUsers] = useState([]);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);

  React.useEffect(() => {
    const getData = async (user) => {
      try {
        setLoader(true);
        if (user.role !== userRolesConst.admin) throw new Error('not allowed!');
        const usersData = await UserDBService.getAllUsers();
        setUsers(usersData);
      } catch (e) {
        setError(true);
        LogService.showAndLogError("get users data error", e);
      } finally {
        setLoader(false);
      }
    };
    if (user) {
      getData(user);
    }
  }, [user]);

  if (loader) return <Loader />
  if (error) return <span>{commonConst.error}</span>;

	return (
	  <div className="UsersList">
      {users.map(user => (
        <div key={user.firebaseId} className='UsersList__user'>
          <div>{user.name}</div>
          <img
            className="UsersList__pic"
            src={user.pic}
            alt=""
          />
        </div>
      ))}
    </div>
  )
};

export default UsersList;

