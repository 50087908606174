import {makeId} from "../helpers/util.helper";

export class TDocument {
  constructor(doc) {
    if (!doc.projectId) throw new Error("no doc.projectId");
    if (!doc.ownerId) throw new Error("no doc.ownerId");

    if (doc.id) {
      this.id = doc.id;
    } else {
      this.id = makeId();
    }

    this.projectId = doc.projectId;
    this.ownerId = doc.ownerId;

    this.name = doc.name || "";
    this.description = doc.description || "";
    this.createdAt = doc.createdAt || Date.now();
    this.files = doc.files || [];

    this.deletedAt = doc.deletedAt || null;
    this.deleted = doc.deleted || false;

    this.files = doc.files || [];
    this.checkFiles();
  };

  checkFiles(){
    this.files.forEach(file => {
      console.log(file);
      if (!file.url) throw new Error('no file.url');
      if (!file.name) throw new Error('no file.name');
      // if (!file.type) throw new Error('no file.type');
      if (!file.size) throw new Error('no file.size');
    })
  }

  setDeleted(){
    this.deletedAt = Date.now();
    this.deleted = true;
    return this;
  }
}
