import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import TEditIcon from "../../material/icons/TEditIcon";
import TDeleteIcon from "../../material/icons/TDeleteIcon";
import {download, showConfirmation} from "../../helpers/util.helper";
import MainContext from "../../contexts/main.context";
import {LogService} from "../../services/LogService";
import {deepCopy} from "@firebase/util";
import EditDocumentPopup from "../popups/EditDocumentPopup/EditDocumentPopup";
import {TDocument} from "../../models/TDocument";
import TAddIcon from "../../material/icons/TAddIcon";
import GetAppIcon from '@material-ui/icons/GetApp';

const DocsTable = ({project, onSuccess}) => {
  const { fireEvent, showSnack, user } = React.useContext(MainContext);
  const [editDocPopup, setEditDocPopup] = React.useState({
    open: false,
    doc: null
  });

  const showEditDocumentPopup = (doc) => {
    try {
      let editedDoc;
      if (doc) {
        editedDoc = deepCopy(doc);
      } else {
        editedDoc = new TDocument({projectId: project.id, ownerId: user.firebaseId});
      }
      setEditDocPopup({
        open: true,
        doc: editedDoc
      });
    } catch (e) {
      LogService.showAndLogError('showCreate document Popup error', e);
    }
  };

  const docRemove = (doc) => {
    try {
      const newState = deepCopy(project);
      const docIndex = newState.docs.findIndex(({id}) => doc.id === id);
      if (!~docIndex) {
        throw new Error('document doesnt exist!');
      } else {
        newState.docs.splice(docIndex, 1);
      }
      onSuccess(newState);
      showSnack("Done!");
    } catch (e) {
      LogService.showAndLogError('doc Removing error', e);
    }
  };

  const closeEditDocPopup = () => {
    setEditDocPopup({
      open: false,
      doc: null
    });
  };

  const onSuccessEditDocPopup = (doc) => {
    try {
      const newState = deepCopy(project);
      const docIndex = newState.docs.findIndex(({id}) => doc.id === id);
      if (!~docIndex) {
        newState.docs.unshift(doc);
      } else {
        newState.docs[docIndex] = doc;
      }
      onSuccess(newState);
    } catch (e) {
      LogService.showAndLogError('doc Editing error', e);
    }
  };

  return (
    <div className='DocsTable'>
      <div>
        Create a Document <TAddIcon onClick={() => showEditDocumentPopup()}/>
      </div>
      <EditDocumentPopup
        open={editDocPopup.open}
        doc={editDocPopup.doc}
        onClose={closeEditDocPopup}
        onSuccess={onSuccessEditDocPopup}
      />
      {project?.docs.length > 0 && (
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Description</TableCell>
                <TableCell align="left">Files</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {project?.docs.map((doc) => (
                <TableRow key={doc.id}>
                  <TableCell component="th" scope="row">
                    {doc.name}
                  </TableCell>
                  <TableCell align="right">{doc.description}</TableCell>
                  <TableCell align="left">
                    {doc.files.length > 0 && (doc.files.map((file) => (
                        <div key={file.url}>
                          {file.name}
                          <GetAppIcon style={{verticalAlign: 'middle', marginLeft: 10, cursor: "pointer"}} onClick={() => download(file.url, file.name)}/>
                        </div>
                      ))
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <TEditIcon onClick={() => showEditDocumentPopup(doc)}/>
                    <TDeleteIcon
                      onClick={() => showConfirmation(fireEvent, ()=> docRemove(doc))}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  )
};

DocsTable.propTypes = {
  project: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default DocsTable;

