import React from "react";

const MainContext = React.createContext({
  event: null,
  fireEvent: () => {},
  user: null,
  updateUser: () => {},
  showSnack: () => {},
});

export default MainContext;
