import React from "react";
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import IconButton from "@material-ui/core/IconButton";

const TMeetingRoomIcon = (props) => {
  return (
    <IconButton {...props}>
      <MeetingRoomIcon />
    </IconButton>
  );
};

export default TMeetingRoomIcon;
