import React, { useState } from "react";
import "./style.scss";
import TButton from "../../../../material/TButton/TButton";
import MainContext from "../../../../contexts/main.context";
import { commonConst } from "../../../../constants/commonConst";
import TTextField from "../../../../material/TTextField";
import { LogService } from "../../../../services/LogService";
import TLink from "../../../../material/TLink/TLink";
import { urlsConst } from "../../../../constants/urlsConst";
import { validEmail } from "../../../../helpers/validator.helper";
import { AuthService } from "../../../../services/AuthService";

const ResetPassPage = () => {
  const { user, showSnack } = React.useContext(MainContext);
  const [email, setEmail] = useState("");

  const submit = async () => {
    try {
      await AuthService.resetPass(email);
      showSnack("Done!");
    } catch (e) {
      LogService.showAndLogError("send reset password error", e);
    }
  };

  if (user) {
    return <span>{commonConst.error}</span>;
  }

  return (
    <div className="ResetPassPage">
      <div className="ResetPassPage__title">Reset your password</div>
      <div className="ResetPassPage__wrapper">
        <TTextField
          onChange={setEmail}
          value={email}
          type="email"
          label="email"
          error={!validEmail(email)}
        />
        <TButton disabled={!validEmail(email)} onClick={submit}>
          Send
        </TButton>
        <div className="ResetPassPage__links">
          <TLink to={urlsConst.login} children="Sign in" />
        </div>
      </div>
    </div>
  );
};

export default ResetPassPage;
