import React, { useState } from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TButton from "../../../material/TButton/TButton";
import { withErrorBoundary } from "../../hoc/withErrorBoundary/withErrorBoundary";
import "./style.scss";
import { eventTypes } from "../../../constants/eventTypes";
import { LogService } from "../../../services/LogService";

const ConfirmationPopup = ({ event, fireEvent }) => {
  const [state, setState] = useState({
    onSuccess: () => {},
    open: false,
  });

  React.useEffect(() => {
    const handleEvent = (event) => {
      try {
        if (event.type !== eventTypes.confirmationPopupState) return;
        if (!event.payload.open) {
          setState((oldState) => {
            return {
              ...oldState,
              open: event.payload.open,
            };
          });
        }

        setState((oldState) => {
          return {
            ...oldState,
            open: event.payload.open,
            onSuccess: event.payload.onSuccess,
          };
        });
      } catch (e) {
        LogService.showAndLogError("handle Event error", e);
      }
    };
    if (event) {
      handleEvent(event);
    }
  }, [event]);

  const onClose = () => {
    fireEvent({
      type: eventTypes.confirmationPopupState,
      payload: { open: false },
    });
  };

  const onSuccess = () => {
    state.onSuccess();
  };

  return (
    <Dialog open={state.open} onClose={onClose}>
      <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText className="ConfirmationPopup">
          Are you Sure?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <TButton onClick={onClose}>Cancel</TButton>
        <TButton
          onClick={() => {
            onSuccess();
            onClose();
          }}
        >
          Ok
        </TButton>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationPopup.propTypes = {
  event: PropTypes.object,
  fireEvent: PropTypes.func.isRequired,
};

export default withErrorBoundary(ConfirmationPopup);
