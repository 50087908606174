import React from "react";
import IconButton from "@material-ui/core/IconButton";
import AppsIcon from '@material-ui/icons/Apps';

const TAppsIcon = (props) => {
  return (
    <IconButton {...props}>
      <AppsIcon />
    </IconButton>
  );
};

export default TAppsIcon;
