import {userRolesConst} from "../constants/userRolesConst";
import NavItem from "../components/utils/NavItem/NavItem";
import {urlsConst} from "../constants/urlsConst";
import TAppsIcon from "../material/icons/TAppsIcon";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import TSettingsIcon from "../material/icons/TSettingsIcon";
import React from "react";
import {LogService} from "../services/LogService";

const getStyle = (path, pathUrl) => {
  try {
    if (path.includes(pathUrl)) return { color: "white" };
    return {};
  } catch (e) {
    LogService.logError("getStyle error", e);
    return {};
  }
};

const getHomeStyle = (path, pathUrl) => {
  try {
    if (path === pathUrl) return { color: "white" };
    return {};
  } catch (e) {
    LogService.logError("getStyle error", e);
    return {};
  }
};

export const getUserNav = ({user, path, history}) => {
  if (user.role === userRolesConst.user) {
    return (
      <>
        <NavItem
          path={path}
          pathUrl={urlsConst.home}
          history={history}
          label="All Projects"
          children={
            <TAppsIcon style={getHomeStyle(path, urlsConst.home)} />
          }
        />
        <NavItem
          path={path}
          pathUrl={urlsConst.settings}
          history={history}
          label="Settings"
          children={
            <TSettingsIcon style={getStyle(path, urlsConst.settings)} />
          }
        />
      </>
    )
  }

  if (user.role === userRolesConst.admin) {
    return (
      <>
        <NavItem
          path={path}
          pathUrl={urlsConst.home}
          history={history}
          label="All Users"
          children={
            <TAppsIcon style={getHomeStyle(path, urlsConst.home)} />
          }
        />
        <NavItem
          path={path}
          pathUrl={urlsConst.userCreation}
          history={history}
          label="User Creation"
          children={
            <AccountTreeIcon style={getStyle(path, urlsConst.userCreation)} />
          }
        />
      </>
    )
  }
}
