import React from "react";
import "./style.scss";
import { AuthService } from "../../../services/AuthService";
import { useHistory } from "react-router";
import Avatar from "@material-ui/core/Avatar";
import MainContext from "../../../contexts/main.context";
import TOffIcon from "../../../material/icons/TOffIcon";
import {urlsConst} from "../../../constants/urlsConst";
import {userRolesConst} from "../../../constants/userRolesConst";

const Header = () => {
  const history = useHistory();
  const { user } = React.useContext(MainContext);

  if (!user) return null;

  if (user.role === userRolesConst.admin) {
    return (
      <div className="Header">
        <div className="Header__menu">
          {user.pic && <Avatar className="avatar" src={user.pic} />}
          {!user.pic && <Avatar className="avatar">{user.name[0]}</Avatar>}
          {user.name}
        </div>
        <div className="Header__main">
          <div> </div>
          <TOffIcon onClick={() => AuthService.signOut(history)} />
        </div>
      </div>
    );
  }

  return (
    <div className="Header">
      <div className="Header__menu">
        {user.pic && <Avatar className="avatar" src={user.pic} />}
        {!user.pic && <Avatar className="avatar">{user.name[0]}</Avatar>}
        <span onClick={() => history.push(urlsConst.settings)} className="clickable">
          {user.name}
        </span>
      </div>
      <div className="Header__main">
        <div> </div>
        <TOffIcon onClick={() => AuthService.signOut(history)} />
      </div>
    </div>
  );
};

export default Header;
