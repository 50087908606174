import React, { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import firebase from "./services/db/firebase";
import MainContext from "./contexts/main.context";
import { getUserRoutes } from "./helpers/routes.helper";
import Header from "./components/layout/Header/Header";
import Content from "./components/layout/Content/Content";
import Nav from "./components/layout/Nav/Nav";
import "./styles/global.scss";
import "./App.scss";
import Loader from "./components/utils/Loader";
import { LogService } from "./services/LogService";
import ErrorPopup from "./components/popups/ErrorPopup";
import { UserDBService } from "./services/db/UserDBService";
import ConfirmationPopup from "./components/popups/ConfirmationPopup/ConfirmationPopup";
import VSnackbar from "./components/utils/VSnackbar/VSnackbar";

const App = () => {
  const [state, setState] = useState({
    user: null,
    loading: true,
    event: null,
  });

  const [error, setError] = React.useState({
    open: false,
    message: "",
  });

  const [snack, setSnack] = React.useState({
    open: false,
    message: "",
  });

  React.useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      try {
        if (user) {
          const userData = await UserDBService.getUserByFirebaseId(user.uid);
          setState((oldState) => ({
            ...oldState,
            user: userData,
            loading: false,
          }));
        } else {
          setState((oldState) => ({
            ...oldState,
            user: null,
            loading: false,
          }));
        }
      } catch (e) {
        LogService.showAndLogError("get user data error", e);
        setState((oldState) => ({
          ...oldState,
          user: null,
          loading: false,
        }));
      }
    });
    return () => {
      firebase.auth().onAuthStateChanged(() => {});
    };
  }, []);

  React.useEffect(() => {
    LogService.showMessage = (message, open) => {
      setError({ message, open });
    };
  }, []);

  const updateUser = (newUser) => {
    setState((oldState) => {
      return { ...oldState, user: newUser };
    });
  };

  const showSnack = (message) => setSnack({ message, open: true });

  const fireEvent = (newEvent) => {
    setState((oldState) => {
      return { ...oldState, event: newEvent };
    });

    //reset event for prevent redundant reactions
    setTimeout(() => {
      setState((oldState) => {
        return { ...oldState, event: null };
      });
    }, 1000);
  };

  if (state.loading) {
    return <Loader />;
  }

  return (
    <MainContext.Provider
      value={{
        user: state.user,
        updateUser,
        event: state.event,
        fireEvent,
        showSnack,
      }}
    >
      <Router>
        <div className="App">
          <ErrorPopup
            open={error.open}
            message={error.message}
            setOpen={(open) => setError((oldState) => ({ ...oldState, open }))}
          />
          <VSnackbar
            message={snack.message}
            open={snack.open}
            onClose={() => setSnack({ message: "", open: false })}
          />
          <ConfirmationPopup fireEvent={fireEvent} event={state.event} />
          <Header />
          <div className={`${state.user ? "App__content" : ""}`}>
            <Nav user={state.user} />
            <Content user={state.user}>{getUserRoutes(state.user)}</Content>
          </div>
        </div>
      </Router>
    </MainContext.Provider>
  );
};

export default App;

// TODO
// капча на сообщение?
// адрес, тексты
// https??
// user creation cloud function https://medium.com/firebase-developers/how-to-generate-and-store-a-pdf-with-firebase-7faebb74ccbf
