export const urlsConst = {
  home: `/`,
  about: `/about`,
  contacts: `/contacts`,
  settings: "/settings",
  createAccount: "/create-account",
  resetPass: "/reset-pass",
  confirmPass: "/confirm-pass",
  login: "/login",
  project: "/project/",

  // admin pages
  userCreation: "/user/create",
};
