import React, {useState} from 'react';
import MainWrapper from "../../components/MainWrapper/MainWrapper";
import TTextField from "../../../../material/TTextField";
import TButton from "../../../../material/TButton/TButton";
import {LogService} from "../../../../services/LogService";
import './style.scss'
import banner from "../../../../assets/banner-contacts.jpg";
import MainContext from "../../../../contexts/main.context";

const Contacts = () => {
  const { showSnack } = React.useContext(MainContext);
  const [message, setMessage] = useState("");
  const submit = async () => {
    try {
      const res = await fetch('https://eng-trainer.com/api/users/email', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({message})
      });

      if (!res.ok) {
        throw new Error('Ошибка');
      }

      setMessage('');
      showSnack('Ваше сообщение получено!');
    } catch (e) {
      LogService.showAndLogError("error!", e);
    }
  };

  return (
    <MainWrapper>
      <img className='banner-img' alt='about us'  src={banner} />
      <div className='our-data'>
        <p>ПП "ПРОЕКТТЕХНОЛОГIЯ"</p>
        <p>49000, Троїцька площа, 5а, Дніпро, Дніпропетровська область</p>
        <p>e-mail: proteh.dp@gmail.com</p>
      </div>

      <div className="ContactForm">
        <div className="ContactForm__title">Залишіть ваше повідомлення</div>
        <div className="ContactForm__wrapper">
          <TTextField
            onChange={setMessage}
            value={message}
            type="text"
            label="повідомлення"
          />
          <TButton disabled={!message || message.length < 7} onClick={submit}>
             послати
          </TButton>
        </div>
      </div>
    </MainWrapper>
  )
};

export default Contacts;

