import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TButton from "../../../material/TButton/TButton";
import { withErrorBoundary } from "../../hoc/withErrorBoundary/withErrorBoundary";
import "./style.scss";
import TTextField from "../../../material/TTextField";
import {setFormState} from "../../../helpers/form.helper";
import {LogService} from "../../../services/LogService";
import {ProjectDBService} from "../../../services/db/ProjectDBService";

const EditProjectPopup = ({ open, onSuccess, onClose, project }) => {
  const [state, setState] = useState(null);
  const [progress, setProgress] = useState(null);
  useEffect(() => {
    setState(project);
  }, [project]);

  const saveProject = async () => {
  	try {
      setProgress(true);
      await ProjectDBService.editProject(state);
      onSuccess(state);
      onClose();
  	} catch (e) {
  		LogService.showAndLogError('save Project error', e);
  	} finally {
      setProgress(false);
    }
  };

  const projectIsValid = (project) => {
  	try {
      if (!project) return false;
      if (!project.name) return false;
      if (!project.description) return false;
      return true;
  	} catch (e) {
  		LogService.logError('projectIsValid error', e);
  	}
  };

  if (!open) return null;
  if (!state) return null;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id="alert-dialog-title">Project: {project.name || 'New Project'}</DialogTitle>
      <DialogContent>
        <div className="EditProjectPopup">
          <TTextField
            style={{width: '100%'}}
            className='EditProjectPopup__text-field'
            onChange={(value) => setFormState("name", value, setState)}
            value={state.name}
            type="name"
            error={!state.name}
            label="Project name"
          />
          <TTextField
            style={{width: '100%'}}
            onChange={(value) => setFormState("description", value, setState)}
            value={state.description}
            type="name"
            error={!state.description}
            label="Project description"
          />
        </div>
      </DialogContent>
      <DialogActions>
        <TButton onClick={onClose}>Cancel</TButton>
        <TButton
          disabled={progress || !projectIsValid(state)}
          onClick={saveProject}
        >
          Save
        </TButton>
      </DialogActions>
    </Dialog>
  );
};

EditProjectPopup.propTypes = {
  project: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withErrorBoundary(EditProjectPopup);
