import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TButton from "../../../material/TButton/TButton";
import { withErrorBoundary } from "../../hoc/withErrorBoundary/withErrorBoundary";
import "./style.scss";
import TTextField from "../../../material/TTextField";
import {setFormState} from "../../../helpers/form.helper";
import {LogService} from "../../../services/LogService";
import DocUploader from "../../utils/DocUploader/DocUploader";
import TDeleteIcon from "../../../material/icons/TDeleteIcon";
import {showConfirmation} from "../../../helpers/util.helper";
import {deepCopy} from "@firebase/util";
import {FilesService} from "../../../services/FilesService";
import MainContext from "../../../contexts/main.context";

const EditDocumentPopup = ({ open, onSuccess, onClose, doc }) => {
  const [state, setState] = useState(null);
  const { fireEvent } = React.useContext(MainContext);

  const [progress, setProgress] = useState(null);

  useEffect(() => {
    setState(doc);
  }, [doc]);

  const addFile = async (file) => {
    try {
      setProgress(true);
      const newState = deepCopy(state);
      newState.files.push(file);
      setState(newState);
    } catch (e) {
      LogService.showAndLogError('save Project error', e);
    } finally {
      setProgress(false);
    }
  };

  const onOkClose = async () => {
  	try {
      setProgress(true);
      const newState = deepCopy(state);
      for (const file of newState.files) {
        if(file.url) continue;
        const url = await FilesService.saveDoc(file);
        newState.files.push({url, name: file.name, type: file.type, size: file.size});
      }

      // we can't save File class, just JS objects
      newState.files = newState.files.filter(({url}) => url);

      onSuccess(newState);
      onClose();
  	} catch (e) {
  		LogService.showAndLogError('onOkClose error', e);
  	} finally {
      setProgress(false);
    }
  };

  const removeFile = async (index) => {
    try {
      const newState = deepCopy(state);
      if (!~index) {
        throw new Error('file doesnt exist!');
      } else {
        newState.files.splice(index, 1);
      }
      setState(newState);
    } catch (e) {
      LogService.showAndLogError('remove file error', e);
    } finally {
      setProgress(false);
    }
  };

  const docIsValid = (editedDoc) => {
    try {
      if (!editedDoc) return false;
      if (!editedDoc.name) return false;
      if (!editedDoc.description) return false;
      if (!editedDoc.files.length) return false;
      return true;
    } catch (e) {
      LogService.logError('docIsValid error', e);
    }
  };

  if (!open) return null;
  if (!state) return null;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id="alert-dialog-title">Edit  Document</DialogTitle>
      <DialogContent>
        <div className="EditDocumentPopup">
          <TTextField
            style={{width: '100%'}}
            className='EditProjectPopup__text-field'
            onChange={(value) => setFormState("name", value, setState)}
            value={state.name}
            type="name"
            error={!state.name}
            label="Document name"
          />
          <TTextField
            style={{width: '100%'}}
            onChange={(value) => setFormState("description", value, setState)}
            value={state.description}
            type="name"
            error={!state.description}
            label="Document description"
          />
          {!state.files.length && <div style={{color: 'red', textAlign: 'center'}}>No files here!</div>}
          {state.files.length > 0 && (
            state.files.map((file, i) => (
              <div style={{display: 'flex', alignItems: 'center', height: 20}}>
                <div key={file.url}>{file.name}</div>
                <TDeleteIcon
                  onClick={() => showConfirmation(fireEvent, ()=> removeFile(i))}
                />
              </div>
            ))
          )}
          <DocUploader onChange={addFile}/>
        </div>
      </DialogContent>
      <DialogActions>
        <TButton onClick={onClose}>Cancel</TButton>
        <TButton
          disabled={progress || !docIsValid(state)}
          onClick={onOkClose}
        >
          Save
        </TButton>
      </DialogActions>
    </Dialog>
  );
};

EditDocumentPopup.propTypes = {
  doc: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withErrorBoundary(EditDocumentPopup);
