import SettingsIcon from "@material-ui/icons/Settings";
import React from "react";
import IconButton from "@material-ui/core/IconButton";

const TSettingsIcon = (props) => {
  return (
    <IconButton {...props}>
      <SettingsIcon />
    </IconButton>
  );
};

export default TSettingsIcon;
