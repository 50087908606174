import React from "react";
import PropTypes from "prop-types";
import TCard from "../../material/TCard";
import "./style.scss";
import TDeleteIcon from "../../material/icons/TDeleteIcon";
import TEditIcon from "../../material/icons/TEditIcon";
import TLink from "../../material/TLink/TLink";
import MainContext from "../../contexts/main.context";
import { LogService } from "../../services/LogService";
import { ProjectDBService } from "../../services/db/ProjectDBService";
import { withErrorBoundary } from "../hoc/withErrorBoundary/withErrorBoundary";
import { showConfirmation } from "../../helpers/util.helper";
import EditProjectPopup from "../popups/EditProjectPopup/EditProjectPopup";
import {urlsConst} from "../../constants/urlsConst";

const ProjectCard = ({ project, projectRemoved, projectEdited }) => {
  const { fireEvent, showSnack } = React.useContext(MainContext);
  const [editProjectPopup, setEditProjectPopup] = React.useState({
    open: false,
    project: null
  });

  const projectRemove = async () => {
    try {
      await ProjectDBService.removeProject(project);
      projectRemoved(project);
      showSnack("Done!");
    } catch (e) {
      LogService.showAndLogError("groupRemove error", e);
    }
  };

  const showEditGroupPopup = (project) => {
    setEditProjectPopup({
      open: true,
      project
    });
  };

  const closeEditProjectPopup = () => {
    setEditProjectPopup({
      open: false,
      project: null
    });
  };

  return (
    <TCard className="ProjectCard">
      <EditProjectPopup
        open={editProjectPopup.open}
        project={editProjectPopup.project}
        onClose={closeEditProjectPopup}
        onSuccess={projectEdited}
      />
      <div className="ProjectCard__edit-button">
        <TEditIcon children="edit" onClick={() => showEditGroupPopup(project)} />
      </div>
      <div className="ProjectCard__delete-button">
        <TDeleteIcon
          children="remove"
          onClick={() => showConfirmation(fireEvent, projectRemove)}
        />
      </div>
      <div className="ProjectCard__info">
        <TLink to={`${urlsConst.project}${project.id}`} children={project.name} />
        <p className="description">{project.description}</p>
      </div>
    </TCard>
  );
};

ProjectCard.propTypes = {
  project: PropTypes.object.isRequired,
  projectEdited: PropTypes.func.isRequired,
  projectRemoved: PropTypes.func.isRequired,
};

export default withErrorBoundary(ProjectCard);
