import React from 'react';
import PropTypes from 'prop-types';
import Card from "@material-ui/core/Card";

const TCard = ({children, className = ''}) => {
	return (
    <Card className={className}>
      {children}
    </Card>
  )
};

TCard.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default TCard;

