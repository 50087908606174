import React from 'react';
import $ from 'jquery';
import {urlsConst} from "../../../../constants/urlsConst";
import './style.scss'
import {useHistory, useLocation} from "react-router";
import logo from "../../../../assets/logo.png";
import TMeetingRoomIcon from "../../../../material/icons/TMeetingRoomIcon";

const Header = () => {
  const history = useHistory();
  const location = useLocation();

  setTimeout(() => {
    const menu = $('#stick-menu');
    const active = menu.find('.active');
    if (active.length) {
      const line = $('#line');
      const default_pos = active.offset().left - menu.offset().left;
      const default_width = active.outerWidth();
      line.css({left: default_pos, width: default_width});

      if (!(menu.find('li').hasClass("active"))) {
        this.find('li').first().addClass('active');
      }

      menu.find('li').hover(function() {
        const self = $(this);
        const diff = self.offset().left - menu.offset().left;
        line.stop().animate({
          width: self.outerWidth(),
          left: diff
        }, 500);

      }, function() {

        line.stop().animate({
          width: default_width,
          left: default_pos
        }, 500);
      });
    }
  }, 1000);

  const getClass = (url) => {
    return location.pathname === url ? 'active' : '';
  };

  return (
    <header style={{ backgroundImage: `url(${logo})`, backgroundRepeat: 'no-repeat', backgroundColor: '#0E2325' }}>
      <div className="menu" id="stick-menu">
        <ul>
          <li onClick={() => history.push(urlsConst.home)} className={getClass(urlsConst.home)}>Главная</li>
          <li onClick={() => history.push(urlsConst.about)} className={getClass(urlsConst.about)}>О нас</li>
          <li onClick={() => history.push(urlsConst.contacts)} className={getClass(urlsConst.contacts)}>Контакты</li>
        </ul>
        <div id="line"></div>
      </div>
      <div className="login-TLink">
        <TMeetingRoomIcon style={{color: '#87CB48'}} onClick={() => history.push(urlsConst.login)}/>
      </div>
    </header>
  )
};

export default Header;

