import React from "react";
import PropTypes from "prop-types";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/styles";

const GlobalLoader = ({ visible }) => {
  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: 100,
      color: "#TrainCardPopup",
    },
  }));
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={visible}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

GlobalLoader.propTypes = {
  visible: PropTypes.bool.isRequired,
};

export default GlobalLoader;
