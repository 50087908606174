import React from "react";
import MainContext from "../../../contexts/main.context";
import "./style.scss";
import { withErrorBoundary } from "../../../components/hoc/withErrorBoundary/withErrorBoundary";
import { LogService } from "../../../services/LogService";
import { commonConst } from "../../../constants/commonConst";
import Loader from "../../../components/utils/Loader";
import ProjectCard from "../../../components/ProjectCard/ProjectCard";
import TAddIcon from "../../../material/icons/TAddIcon";
import EditProjectPopup from "../../../components/popups/EditProjectPopup/EditProjectPopup";
import {deepCopy} from "@firebase/util";
import {ProjectModel} from "../../../models/ProjectModel";
import {ProjectDBService} from "../../../services/db/ProjectDBService";

const ProjectsPage = () => {
  const { user  } = React.useContext(MainContext);
  const [error, setError] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [projects, setProjects] = React.useState([]);
  const [editProjectPopup, setEditProjectPopup] = React.useState({
    open: false,
    project: null
  });

  const getProjects = React.useCallback(async (user) => {
    try {
      setLoader(true);
      setError(false);
      const projects = await ProjectDBService.getUserProjects(user);
      setProjects(projects);
    } catch (e) {
      setError(true);
      LogService.showAndLogError("get projects error", e);
    } finally {
      setLoader(false);
    }
  }, []);

  React.useEffect(() => {
    if (user) {
      getProjects(user);
    }
  }, [user, getProjects]);

  if (error) return <span>{commonConst.error}</span>;
  if (loader) return <Loader />;

  const openEditProjectPopup = () => {
  	try {
  	  const newProject = new ProjectModel({ownerId: user.firebaseId})
      setEditProjectPopup({
        open: true,
        project: newProject
      });
  	} catch (e) {
  		LogService.showAndLogError('openEditProjectPopup error', e);
  	}
  };

  const onSuccessEditProjectPopup = (newProject) => {
    setProjects(oldState => {
      const newState = deepCopy(oldState);
      newState.unshift(newProject);
      return newState;
    });
  };

  const closeEditProjectPopup = () => {
    setEditProjectPopup({
      open: false,
      project: null
    });
  };

  const projectEdited = (project) => {
  	try {
  	  const newProjects = deepCopy(projects);
      setProjects(newProjects.map(item => {
        if (item.id === project.id) {
          return project;
        } else {
          return item;
        }
      }));
  	} catch (e) {
  		LogService.showAndLogError('project Editing error', e);
  	}
  };

  const projectRemoved = (project) => {
  	try {
      const newProjects = deepCopy(projects);
      const index = newProjects.findIndex(({id}) => project.id === id);
      newProjects.splice(index, 1);
      setProjects(newProjects);
  	} catch (e) {
  		LogService.showAndLogError('project Removing error', e);
  	}
  };

  return (
    <div className="ProjectsPage">
      <EditProjectPopup
        open={editProjectPopup.open}
        project={editProjectPopup.project}
        onClose={closeEditProjectPopup}
        onSuccess={onSuccessEditProjectPopup}
      />
      <div className="ProjectsPage__title">
        Projects: {projects.length} <TAddIcon onClick={openEditProjectPopup}/>
      </div>
      <div className="ProjectsPage__wrapper">
        {projects.map((project) => (
          <ProjectCard
            projectEdited={projectEdited}
            projectRemoved={projectRemoved}
            key={project.id}
            project={project} />
        ))}
      </div>
    </div>
  );
};

export default withErrorBoundary(ProjectsPage);
