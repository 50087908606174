import React from "react";
import PropTypes from "prop-types";
import "./style.scss";
import { LogService } from "../../../services/LogService";
import Button from "@material-ui/core/Button";

const DocUploader = ({onChange}) => {
  const picOnChoose = async (e) => {
    try {
      const file = e.target.files[0];
      // if (!file.type.includes("application/pdf")) throw new Error("this type is not supported");
      onChange(file);
    } catch (e) {
      LogService.showAndLogError("file saving error", e);
    }
  };

  return (
    <div className="DocUploader">
      <Button
        variant="contained"
        component="label"
      >
        Upload a File
        <input
          accept="pdf"
          id="contained-button-file"
          onChange={picOnChoose}
          type="file"
          hidden
        />
      </Button>
    </div>
  );
};

DocUploader.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default DocUploader;
