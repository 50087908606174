import React, { useState } from "react";
import "./style.scss";
import TButton from "../../../material/TButton/TButton";
import MainContext from "../../../contexts/main.context";
import TTextField from "../../../material/TTextField";
import { setFormState } from "../../../helpers/form.helper";
import { LogService } from "../../../services/LogService";
import { withErrorBoundary } from "../../../components/hoc/withErrorBoundary/withErrorBoundary";
import PicChanger from "../../../components/utils/PicChanger/PicChanger";
import { UserDBService } from "../../../services/db/UserDBService";
import { FilesService } from "../../../services/FilesService";

const SettingsPage = () => {
  const { user, updateUser, showSnack, event } = React.useContext(MainContext);
  const [state, setState] = useState({ ...user });
  const [file, setFile] = useState(null);
  const [blockPicker, setBlockPicker] = useState(false);

  React.useEffect(() => {
    const handleEvent = async (event) => {
      try {
        if (event.payload.open) {
          setBlockPicker(true);
        } else {
          setBlockPicker(false);
        }
      } catch (e) {
        LogService.showAndLogError("handle Event error", e);
      }
    };
    if (event) {
      handleEvent(event);
    }
  }, [event]);

  const submitProfile = async (newUser) => {
    try {
      const user = { ...newUser };
      if (file) {
        user.pic = await FilesService.savePic(file);
      }
      await UserDBService.updateUser(user);
      updateUser(user);
      showSnack("Success!");
    } catch (e) {
      LogService.showAndLogError("save user error", e);
    }
  };

  const picOnChange = ({ base64, file }) => {
    try {
      setFormState("pic", base64, setState);
      setFile(file);
    } catch (e) {
      LogService.showAndLogError("pic Changing error", e);
    }
  };

  return (
    <div className="SettingsPage">
      <div className="SettingsPage__wrapper">
        <div className="SettingsPage__title">Edit Account Data</div>
        <PicChanger
          id="SettingsPage-PicChanger"
          alt="profile-pic"
          pic={state.pic}
          blockPicker={blockPicker}
          onChange={(value) => {
            picOnChange(value);
          }}
        />
        <TTextField
          onChange={(value) => setFormState("name", value, setState)}
          value={state.name}
          type="name"
          error={!state.name}
          label="your name"
        />
        <TButton disabled={!state.name} onClick={() => submitProfile(state)}>
          Save
        </TButton>
      </div>
    </div>
  );
};

export default withErrorBoundary(SettingsPage);
