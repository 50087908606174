import firebase from "./db/firebase";
import {makeId} from "../helpers/util.helper";

export class FilesService {
  static maxPicSize = 300000;
  static maxDocSize = 500000;

  static getExtension(path) {
    // extract file name from full path ...
    const basename = path.split(/[\\/]/).pop();

    // (supports `\\` and `/` separators)
    // get last position of `.`
    const pos = basename.lastIndexOf(".");

    // if file name is empty or ...
    //  `.` not found (-1) or comes first (0)
    if (basename === "" || pos < 1) {
      return "";
    }

    return basename.slice(pos + 1);
  }

  static save(uploadTask) {
    return new Promise(function(resolve, reject) {
      uploadTask.on(
        "state_changed",
        function(snapshot) {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          // const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // console.log('Upload is ' + progress + '% done');
          // switch (snapshot.state) {
          //   case firebase.storage.TaskState.PAUSED: // or 'paused'
          //     console.log('Upload is paused');
          //     break;
          //   case firebase.storage.TaskState.RUNNING: // or 'running'
          //     console.log('Upload is running');
          //     break;
          // }
        },
        function(error) {
          reject(error);

          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;
            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
            default:
            // Unknown error occurred
          }
        },
        function() {
          uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            resolve(downloadURL);
          });
        }
      )
    });
  }

  static savePic(file) {
    if (file.size > FilesService.maxPicSize) {
      throw new Error("too big!");
    }

    const storageRef = firebase.storage().ref();
    const metadata = {
      contentType: file.type,
    };

    const uploadTask = storageRef
      .child(`images/${makeId()}.${FilesService.getExtension(file.name)}`)
      .put(file, metadata);

    return FilesService.save(uploadTask);
  }

  static saveDoc(file) {
    if (file.size > FilesService.maxDocSize) {
      throw new Error("too big!");
    }

    const storageRef = firebase.storage().ref();
    const metadata = {
      contentType: file.type,
    };

    const uploadTask = storageRef
      .child(`docs/${makeId()}.${FilesService.getExtension(file.name)}`)
      .put(file, metadata);

    return FilesService.save(uploadTask);
  }

  static getBase64(file) {
    return new Promise((resolve, reject) => {
      if (file.size > FilesService.maxDocSize)
        return reject(new Error("too big!"));

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
}
