const emailPattern = /^.+@.+$/;
const passwordPattern = /^(?=.*\d)(?=.*[a-z]).{8,64}$/;

export const validEmail = (email) => {
  if (email) return emailPattern.test(email);
  return false;
};

export const validPassword = (password) => {
  if (password) return passwordPattern.test(password);
  return false;
};
