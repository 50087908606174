import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./style.scss";
import List from "@material-ui/core/List";
import { withRouter } from "react-router";
import {getUserNav} from "../../../helpers/layout.helper";

const Nav = ({ user, history }) => {
  const [path, setPath] = useState(history.location.pathname);

  useEffect(() => {
    setPath(history.location.pathname);
  }, [history.location.pathname]);

  if (!user) return null;

  return (
    <div className="Nav custom-scroll">
      <List component="nav" aria-label="main mailbox folders">
        {getUserNav({user, path, history})}
      </List>
    </div>
  );
};

Nav.propTypes = {
  user: PropTypes.object,
};

export default withRouter(Nav);
