import React from 'react';
import './style.scss'

const Footer = () => {
	return (
    <div style={{marginTop: 'auto'}}>
      <section className='Footer'>
        <div className='text'>All rights reserved 2022</div>
      </section>
    </div>
  )
};

export default Footer;

