import React from 'react';
import MainWrapper from "../../components/MainWrapper/MainWrapper";
import banner from '../../../../assets/banner-home.jpg';
import Content from "../../components/Content/Content";

const Main = () => {
  return (
    <MainWrapper>
      <img className='banner-img' alt='home' src={banner} />
      <Content>
        <p></p>
        Проведение любых видов строительных работ – это сложный процесс, который включает в себя множество
        этапов. И одним из первых и самых важных среди них является проектирование. Без проектирования не обойтись и при
        строительстве жилых зданий, и при возведении промышленных сооружений. От того, насколько качественно было
        выполнено проектирование, зависит не только внешний вид здания и его функциональность, но и затраты на
        строительство, и надежность здания, и его долговечность. Поэтому любые виды проектных работ должны выполняться
        на высоком профессиональном уровне, с использованием не только знаний в соответствующей области, но и
        практического опыта, и новейших компьютерных технологий. Наша компания, имеющая значительный опыт проведения
        проектных работ любого масштаба и сложности, предлагает своим заказчикам широкий спектр услуг, связанных с
        проектированием сооружений и зданий различного назначения.


        <p></p>
        Одной из востребованных в настоящее время технологий является строительство быстровозводимых зданий на основе
        металлоконструкций. Наши специалисты ведут проектирование металлоконструкций на высоком качественном уровне, с
        учетом всех особенностей конкретного объекта и особенностей металлоконструкций того или иного производителя.
        Созданная нами на основе технического задания заказчика рабочая документация позволит в дальнейшем выполнить все
        необходимые виды строительных работ, с максимальной экономической эффективностью.

        <p></p>

        Одним из важных направлений нашей работы является проектирование сооружений. В настоящее время, мы имеем опыт
        проектирования объектов различного назначения – сооружений связи, сооружений спортивного и развлекательного
        назначения, канализационных и многих других видов сооружений. Квалифицированный подход наших специалистов к
        потребностям каждого нашего заказчика позволяет вам быть уверенными в том, что проектирование, выполненное на
        основе всех существующих норм и стандартов, будет отвечать всем современным требованиям качества.


        <p></p>
        Что касается такой области нашей деятельности, как проектирование промышленных зданий, то создаваемые на основе
        наших проектов здания отличаются минимальными сроками строительства, мобильностью, широкой функциональностью и
        высокой экономической эффективностью, которая проявляется как на стадии строительства, так и в процессе их
        эксплуатации. Заказывая у нас работы по этому направлению, вы можете быть уверены, что мы предложим вам на выбор
        несколько вариантов, из которых вы выберете наиболее оптимальный для себя. Индивидуальный подход к формированию
        стоимости услуг в области проектирования позволяет каждому нашему заказчику быть уверенным в рациональном
        расходовании его бюджета.

        <p></p>

        Услуги в области проектирования в настоящее время являются исключительно востребованными, учитывая и
        продолжающуюся урбанизацию, и расширение мощностей многих производственных предприятий. В этой области ведет
        деятельность достаточно большое количество строительных компаний и проектных бюро. Отдавая предпочтение одному
        из них, необходимо обращать внимание не только на предлагаемые цены, но и на ассортимент оказываемых услуг, а
        также на общий уровень квалификации и профессионализма штатных специалистов. Имея значительный практический опыт
        и множество реализованных проектов в таких областях, как проектирование промышленных зданий, жилых зданий и
        сооружений различного назначения, специалисты нашей компании способны гарантировать неизменно высокое качество
        работ при создании проектов любой сложности.
      </Content>
    </MainWrapper>
  )
};

export default Main;

