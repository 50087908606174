import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";

const TTextField = ({
  style,
  onChange,
  label,
  type,
  value = "",
  error,
  autoFocus = false,
  multiline = true,
}) => {
  return (
    <TextField
      multiline={multiline}
      autoFocus={autoFocus}
      style={{ marginTop: 20, ...style }}
      label={label}
      type={type}
      value={value}
      error={error}
      onChange={(value) => onChange(value.target.value)}
    />
  );
};

TTextField.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
  error: PropTypes.bool,
  autoFocus: PropTypes.bool,
  multiline: PropTypes.bool,
};

export default TTextField;
