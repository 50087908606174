import React from "react";

const ErrorPage = () => {
  return (
    <div className="text-center">
      <img
        style={{ objectFit: "contain", width: 450, height: 450 }}
        src="https://firebasestorage.googleapis.com/v0/b/vocabulary-dev-7e027.appspot.com/o/common%2F404.jpg?alt=media&token=5be10e62-95e1-45c8-8269-a7b83939c8c2"
        alt="sad kitty"
      />
    </div>
  );
};

export default ErrorPage;
