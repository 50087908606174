import React from "react";
import { LogService } from "../../../services/LogService";
import "./style.scss";
import { withErrorBoundary } from "../../../components/hoc/withErrorBoundary/withErrorBoundary";
import { withRouter } from "react-router";
import Loader from "../../../components/utils/Loader";
import { commonConst } from "../../../constants/commonConst";
import {ProjectDBService} from "../../../services/db/ProjectDBService";
import DocsTable from "../../../components/DocsTable/DocsTable";

const ProjectPage = ({ match }) => {
  const [error, setError] = React.useState(false);
  const [loader, setLoader] = React.useState(true);
  const [project, setProject] = React.useState(null);

  React.useEffect(() => {
    const getData = async (projectId) => {
      try {
        const project = await ProjectDBService.getProjectById(projectId);
        setProject(project);
      } catch (e) {
        setError(true);
        LogService.showAndLogError("get data error", e);
      } finally {
        setLoader(false);
      }
    };
    if (match.params.id) {
      getData(match.params.id);
    }
  }, [match.params.id]);

  const updateProject = async (project) => {
  	try {
      await ProjectDBService.editProject(project);
      setProject(project);
  	} catch (e) {
  		LogService.showAndLogError('update Project error', e);
  	}
  };

  if (error) return <span>{commonConst.error}</span>;
  if (loader) return <Loader />;
  if (!project) return null;

  return (
    <div className="ProjectPage">
      <div className="ProjectPage__header">
        <div className="ProjectPage__info header-item">
          <div style={{ marginRight: 10 }}>
            <div className="ProjectPage__title">Name: {project.name}</div>
            <div className="ProjectPage__sub-title">
              Description: {project.description}
            </div>
          </div>
        </div>
      </div>
      <DocsTable
        project={project}
        onSuccess={updateProject}
      />
    </div>
  );
};

export default withRouter(withErrorBoundary(ProjectPage));
