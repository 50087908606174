import React from "react";
import PropTypes from "prop-types";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import "./style.scss";

const NavItem = ({ pathUrl, path, history, children, label }) => {
  return (
    <ListItem
      selected={path === pathUrl}
      button
      onClick={() => history.push(pathUrl)}
    >
      <ListItemIcon>{children}</ListItemIcon>
      <ListItemText primary={label} className="Nav-item" />
    </ListItem>
  );
};

NavItem.propTypes = {
  pathUrl: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};

export default NavItem;
